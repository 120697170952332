import { ROLES, ROUTE_NAMES, ROUTE_PATHS } from "@/lib/constants"

export default [
  // Admin Routes Panel
  {
    path: ROUTE_PATHS.ADMIN.ROUTES.PANEL,
    component: () => import(/* webpackChunkName: "route-panel-layout" */ "../../components/layouts/router-view/AppRouterView.vue"),
    children: [
      {
        // Default view
        path: ROUTE_PATHS.EMPTY,
        name: ROUTE_NAMES.ADMIN.ROUTES.PANEL,
        component: () => import(/* webpackChunkName: "route-panel" */ "./modules/routes/panel/Index.vue")
      },
      {
        // Route details
        props: true,
        path: ROUTE_PATHS.ADMIN.ROUTES.DETAILS,
        name: ROUTE_NAMES.ADMIN.ROUTES.DETAILS,
        component: () => import(/* webpackChunkName: "route-panel-details" */ "./modules/routes/details/Index.vue")
      }
    ]
  },

  // PDIS
  {
    path: ROUTE_PATHS.ADMIN.PDIS.LIST,
    component: () => import(/* webpackChunkName: "admin-pdis-layout" */ "../../components/layouts/router-view/AppRouterView.vue"),
    children: [
      {
        // List
        path: ROUTE_PATHS.EMPTY,
        name: ROUTE_NAMES.ADMIN.PDIS.LIST,
        component: () => import(/* webpackChunkName: "admin-pdis-list" */ "./modules/pdis/list/Index.vue")
      },
      {
        // Edit
        props: true,
        path: ROUTE_PATHS.ADMIN.PDIS.EDIT,
        name: ROUTE_NAMES.ADMIN.PDIS.EDIT,
        component: () => import(/* webpackChunkName: "admin-pdis-edit" */ "./modules/pdis/edit/Index.vue")
      }
    ]
  },

  // Alerts
  {
    path: ROUTE_PATHS.ADMIN.ALERTS,
    name: ROUTE_NAMES.ADMIN.ALERTS,
    component: () => import(/* webpackChunkName: "admin-alerts" */ "./modules/alerts/Index.vue")
  },

  // Profile
  {
    path: ROUTE_PATHS.ADMIN.PROFILE,
    name: ROUTE_NAMES.ADMIN.PROFILE,
    component: () => import(/* webpackChunkName: "admin-profile" */ "./modules/profile/Index.vue")
  },

  // Settings
  {
    path: ROUTE_PATHS.ADMIN.SETTINGS,
    name: ROUTE_NAMES.ADMIN.SETTINGS,
    component: () => import(/* webpackChunkName: "admin-settings" */ "./modules/settings/Index.vue"),
    meta: {
      role: [ROLES.SUPER_ADMIN]
    }
  },

  // Users
  {
    path: ROUTE_PATHS.ADMIN.USERS,
    name: ROUTE_NAMES.ADMIN.USERS,
    component: () => import(/* webpackChunkName: "admin-users" */ "./modules/users/Index.vue")
  },

  // Reports
  {
    path: ROUTE_PATHS.ADMIN.REPORTS,
    name: ROUTE_NAMES.ADMIN.REPORTS,
    component: () => import(/* webpackChunkName: "admin-reports" */ "./modules/reports/Index.vue"),
    meta: {
      role: [ROLES.SUPER_ADMIN, ROLES.ADMIN]
    }
  }
]
