export const ROUTE_NAMES = {
  HOME: "home",

  // Auth
  AUTH: {
    LOGIN: "login",
    FORGOT_PASSWORD: "forgot-password"
  },

  // ------------------------------------ Admin Entity -------------------
  ADMIN: {
    // Routes
    ROUTES: {
      PANEL: "admin-routes-panel",
      DETAILS: "admin-routes-panel-details"
    },

    // PDI's
    PDIS: {
      LIST: "admin-pdis",
      CREATE: "admin-pdis-create",
      EDIT: "admin-pdis-edit"
    },

    // Alerts
    ALERTS: "admin-alerts",

    // Profile
    PROFILE: "admin-profile",

    // Settings
    SETTINGS: "admin-settings",

    // Users
    USERS: "admin-users",

    // Reports
    REPORTS: "admin-reports"
  },

  // -------------------------------------------- Providers Entity ---------------
  PROVIDERS: {
    // Profile
    PROFILE: "providers-profile",

    // Contracted Routes
    CONTRACTED_ROUTES: "providers-contracted-routes",

    // Daily Routes
    DAILY_ROUTES: "providers-daily-routes",

    // Retainers
    RETAINERS: "providers-retainers",

    // Drivers
    DRIVERS: "providers-drivers",

    // Vehicles
    VEHICLES: "providers-vehicles",

    // Trailers
    TRAILERS: "providers-trailers"
  },

  // Globals
  ERRORS: {
    FORBIDDEN: "forbidden",
    NOT_FOUND: "not-found"
  }
}

// It is important that the values of these objects are not repeated to avoid errors with the Vue-Router
Object.freeze(ROUTE_NAMES)