import { verifyTokenGuard } from "./verify-token.guard"

import { ROUTE_NAMES } from "@/lib/constants"
import { useAuthStore } from "@/stores"

export const authGuard = async (next, roleArray) => {
  const auth = useAuthStore()

  // If User is not Authenticated, redirect to login
  if (auth.isAuthenticated) {

    // Check Role
    if (roleArray && !auth.isRole(roleArray)) {
      next({ name: ROUTE_NAMES.ERRORS.FORBIDDEN })
      return
    }

    // Continue with Process
    verifyTokenGuard()
    next()
  }
  else next({ name: ROUTE_NAMES.AUTH.LOGIN })
}