import * as yup from "yup"

import { tc } from "@/lib/services"

const commonValidations = {
  /* Which Report */
  WhichReport: yup.string().required(tc("Validations.Required")),

  /* Which Date */
  ReportDate: yup.date().required(tc("Validations.Required")),

  /* All POIs */
  AllPois: yup.boolean(),

  /* A certain POI */
  PoiID: yup.string()
}

export default {
  // Form Data
  data: {
    WhichReport: "delays",
    ReportDate: "",
    AllPois: false,
    PoiID: null
  },

  // Form Validations
  schema: yup.object().shape({
    ...commonValidations
  })
}
